
import { createAction, props } from '@ngrx/store';
import { Action } from '@ngrx/store';
import { UserState } from '@core/store/user';
import { EMPTY } from 'rxjs';

export enum UserActionTypes {
    setUser = '[User] SET_USER',
    getUser = '[User] GET_USER',
    clearUser = '[User] CLEAR_USER',
    currentRole = '[User] CURRENT_ROLE',
    checkAuth = '[User] USER_CHECK_AUTH',

}



export const setUser = createAction(UserActionTypes.setUser, props<UserState | typeof EMPTY>())
export const getUser = createAction(UserActionTypes.getUser)
export const clearUser = createAction(UserActionTypes.clearUser)
export const currentRole = createAction(UserActionTypes.currentRole)
export const checkAuth = createAction(UserActionTypes.checkAuth)
