import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
//SERVICE
import { LocalStorageService } from '@core/services/local-storage.service';
import { AuthService } from '@auth/store';
//ACTIONS
import * as authActions from '@auth/store/auth.actions';
import * as errorActions from '@core/store/error/error.actions'
import * as userActions from '@core/store/user'
//STORE

import { AppState } from '@core/store/core.state';




export const AUTH_KEY = 'AUTH';

@Injectable({ providedIn: 'root' })
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private storageService: LocalStorageService
  ) { }



  authenticate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authAuthenticate),
        exhaustMap(action => this.authService.authenticate({ ...action }).pipe(
          map(res => {
            this.store.dispatch(authActions.authToken(res.data));
            this.storageService.setItem(this.authService.storageKey, res.data);
            this.store.dispatch(userActions.getUser());
            this.router.navigate(['admin', 'user']);
          }),
          catchError((error => of(errorActions.createError({ action: action.type, errors: error }))))
        )
        )




        //  .subscribe({
        //   next: (res) => {
        //     map(res.data)
        //     this.store.dispatch(authActions.authToken(res.data));
        //     
        //     this.store.dispatch();
        //    ;
        //   },
        //   error: (error) => {
        //     ;
        //   }
        // })

      ),

    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authLogout),
        tap(() => {
          this.authService.logout().subscribe(() => {
            this.storageService.removeItem(this.authService.storageKey);
            this.store.dispatch(userActions.clearUser())
            this.router.navigate(['/'])
          })
        })
      ),
    { dispatch: false }
  );
}
