
import * as authActions from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { AuthState } from '@auth/store/auth.models';

export const initialState: AuthState = {};

const reducer = createReducer(
  initialState,
  on(authActions.authToken, (state, action) => ({ ...state, ...action })),
  on(authActions.authLogout, (state, action) => ({})),
  on(authActions.authRefreshToken, (state, action) => ({ ...state, refresh_token: action.refresh_token }))
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}
