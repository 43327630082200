import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, } from '@core/services';
import { environment } from '../../environments/environment';
import { ApiResponse } from '@core/models';
import { LocalStorageService } from '@core/services';
import { UserLoginModel } from '@core/models';


@Injectable({ providedIn: 'root' })

export class AuthService extends ApiService {

    AUTH_KEY = 'AUTH'

    //TROCA O ENDPOINT DA API
    override url?: string | undefined = environment.api.url.auth;


    constructor(override  http: HttpClient, private storageService: LocalStorageService) {

        super(http)
    }

    authenticate(credentials: UserLoginModel): Observable<any> {

        // const urlService = this.setApiServiceWithPage('books/search', page)
        let urlService = this.setApiService('login');
        const _data = { email: credentials.email, password: credentials.password, verify_token: credentials.verify_token }

        return this.post(urlService, _data);

    }

    refresh(credentials: UserLoginModel): Observable<any> {

        // const urlService = this.setApiServiceWithPage('books/search', page)
        let urlService = this.setApiService('refresh');
        const _data = { email: credentials.email, password: credentials.password, verify_token: credentials.verify_token }

        return this.post(urlService, _data);

    }
    logout() {
        let urlService = this.setApiService('logout');
        return this.get(urlService);
    }
    // getBooksRecentsEditions() {

    //     const urlService = this.setApiService('books/recentseditions')

    //     return this.get(urlService);

    // }
    // getBookBySlug(slug: string | null) {

    //     const urlService = this.setApiServiceById('books/slug', slug)

    //     return this.get(urlService);

    // }

    // checkBookViewed(id: number): void {
    //     let _book = this.storageService.getItem(BOOKS_VIEWS_KEY) || [];
    //     const urlService = this.setApiServiceById('books/markviews', id)

    //     if (!_book.includes(id)) {

    //         this.get(urlService).subscribe((res) => {
    //             _book.push(id)
    //             this.storageService.setItem(BOOKS_VIEWS_KEY, _book)
    //         })
    //     }
    // }

    // checkBookDownloaded(id: number): void {
    //     let _book = this.storageService.getItem(BOOKS_DOWNLOADS_KEY) || [];

    //     if (!_book.includes(id)) {
    //         _book.push(id);
    //         this.storageService.setItem(BOOKS_VIEWS_KEY, _book);
    //         const urlService = this.setApiServiceById('books/markdownloads', id);
    //         this.get(urlService).subscribe((res) => {
    //             _book.push(id)
    //             this.storageService.setItem(BOOKS_DOWNLOADS_KEY, _book)
    //         })

    //     }
    // }

    get storageKey(): string {
        return this.AUTH_KEY;
    }
}
