
import { createReducer, on, Action } from '@ngrx/store';
import { UserState } from '@core/store/user'
import * as userActions from '@core/store/user/user.action'
import { EMPTY } from 'rxjs';
const userInialState: UserState = {

};

const reducer = createReducer(
  userInialState,
  on(userActions.setUser, (state, action) => ({ ...state, ...action })),
  // on(userActions.setUser, (state, { user }) => ({ ...state,  user:  user})),

  on(userActions.clearUser, (state) => ({}))
);

export function userReducer(
  state: UserState | undefined,
  action: Action
): UserState {
  return reducer(state, action);
}
