import { createAction, props, Action } from '@ngrx/store';
import { UserLoginModel } from '@core/models';
import { AuthState } from '@auth/store/auth.models';

export enum AuthActionTypes {
    authAuthenticate = '[AUTH] Login',
    authLogout = '[AUTH] Logout',
    authToken = '[AUTH] Token',
    authError = '[AUTH] Error',
    authRefreshToken = '[AUTH] RefreshToken',
}




// export const authLogin  = (userLoginModel: UserLoginModel)=> {

//     return <Action> { type: ActionTypes.authLogin, payload: userLoginModel }
// }

// createAction(ActionTypes.authLogin, props<{userLoginModel: UserLoginModel}>() );


export const authAuthenticate = createAction(
    AuthActionTypes.authAuthenticate,
    props<UserLoginModel>()
);
export const authLogout = createAction(
    AuthActionTypes.authLogout);

export const authToken = createAction(
    AuthActionTypes.authToken,
    props<{ authToken: AuthState }>()
);
export const authRefreshToken = createAction(
    AuthActionTypes.authRefreshToken,
    props<{ refresh_token: string }>()
);

export const authError = createAction(
    AuthActionTypes.authError,
    props<{ error: any }>()
);