import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import { AuthState } from '@auth/store/auth.models';

export const selectAuthState = createFeatureSelector<AuthState>('auth');


export const selectAuth = createSelector(
  selectAuthState,
  (state: AuthState) => state
);

export const selectAuthAccessToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.access_token
);
export const selectAuthIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.access_token ? true : false
);

export const selectAuthTokenExpiresIn = createSelector(
  selectAuthState,
  (state: AuthState) => state?.expires_in
);
export const selectAuthTokentype = createSelector(
  selectAuthState,
  (state: AuthState) => state?.token_type
);

