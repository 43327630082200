

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '@core/services'
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf, Observable, BehaviorSubject, Subject } from 'rxjs';
import { User, Roles, RolesRoutes } from '@core/models';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store/core.state';
import *  as  userSelectors from '@core/store/user/user.selectors'
import { UserState } from '@core/store/user/user.state'
import { environment as env } from '~app/src/environments/environment';

@Injectable({ providedIn: 'root' })

export class UserService extends ApiService {

  AUTH_KEY = 'AUTH'

  //TROCA O ENDPOINT DA API
  override url?: string | undefined = env.api.url.backend;
  //API

  avatar(avatar: string): Observable<any> {

    this.headers = new HttpHeaders()
    this.headers = this.headers.append('enctype', 'multipart/form-data');
    this.headers = this.headers.append('X-Requested-With', 'XMLHttpRequest');

    let urlService = this.setApiService('admin/user/profile/avatar');
    const _data = { avatar: avatar }

    console.log(_data)

    return this.post(urlService, _data)
  }


  me(): Observable<any> {
    let urlService = this.setApiService('admin/user/profile/me');
    return this.get(urlService);
  }
  identification(user: User): Observable<any> {
    let urlService = this.setApiService('admin/user/profile/identification');
    const _data = {
      name: user.name,
      mobile: user.mobile,
      document: user.document,
      country_id: user.country_id
    };

    return this.post(urlService, _data);
  }

  password(data: { password_current: string, password: string }): Observable<any> {
    let urlService = this.setApiService('admin/user/profile/password');
    return this.post(urlService, data);
  }



}
