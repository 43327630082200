import { createAction, props } from '@ngrx/store';

import { ErrorState } from './';

export const createError = createAction(
  '[Error] Create Error',
  props<ErrorState>()
);

export const deleteError = createAction(
  '[Error] Delete Error',
  props<ErrorState>()
);
export const clearErrors = createAction(
  '[Error] Clear Error'
);
